



















































import { VForm } from "@/types/vForm";
import {
  StatementChargeRecurrenceType,
  StatementChargeUpsertModel
} from "@/api/generated";
import {
  Vue,
  Component,
  PropSync,
  Prop,
  Ref,
  Watch
} from "vue-property-decorator";
import api from "@/api";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import StatementChargeRecurrenceSelect from "@/components/common/statement/StatementChargeRecurrenceSelect.vue";
import { StringStringConstantModel } from "@/api/generated";

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { StatementChargeRecurrenceSelect } })
export default class StatementChargeCreateDialog extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop(String) private statementId!: string;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private invoiceDescription: Array<StringStringConstantModel> = [];
  private model: StatementChargeUpsertModel = {
    description: "",
    quantity: 0,
    unitAmount: 0,
    recurrenceType: StatementChargeRecurrenceType.NUMBER_0
  };
  private items: string[] = [
    "Accomodation",
    "Covid Test",
    "Flight",
    "Flight ABZ-LHR-ACC",
    "Flight ABZ-LHR-BAH-CMB",
    "Flight ABZ-LHR-CMB",
    "Flight ACC-LHR-ABZ",
    "Flight CMB-BAH-LHR-ABZ",
    "Flight CMB-LHR-ABZ",
    "Initial Contract Set Up Fee",
    "Mobilisation Fee & POE Registration India",
    "Mobilisation Fee & POE Registration Sri Lanka",
    "Monthly Agents Fee",
    "Quarantine Costs",
    "Transport"
  ];

  @Watch("syncedDialog")
  private onDialogChange() {
    if (this.syncedDialog) {
      return;
    }

    this.form.reset();
  }

  private async created() {
    await this.invoiceDescriptions();
  }

  private async invoiceDescriptions() {
    // try {
    //   const response = await api.ConstantApi.apiConstantInvoiceDesriptionGet();
    //   this.invoiceDescription = response.data;
    // } catch {
    //   snackbarModule.setSnackbarMessage(
    //     "Failed to retrieve Invoice Descriptions"
    //   );
    // }
  }

  private async onCreateLineItem() {
    this.model.statementId = this.statementId;
    try {
      this.loading = true;
      await api.StatementService.apiStatementChargePost(this.model);
      snackbarModule.setSnackbarMessage("Invoice line item created");
      this.$emit("charge-created");
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to create line item for invoice"
      );
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}







































import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import { StatementChargeModel, StatementModel } from "@/api/generated";
import Api from "@/api";
import nameof from "@/utility/nameof";
import SnackbarModule from "@/store/snackbarModule";
import { getModule } from "vuex-module-decorators";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class VesselEnquiryInvoiceTable extends Vue {
  @Prop(String) private vesselEnquiryId!: string;
  private statement: StatementModel = {};
  private loading = false;
  private headers: Array<DataTableHeader<StatementChargeModel>> = [
    { text: "", value: "actions", sortable: false },
    { text: "Description", value: nameof<StatementChargeModel>("description") },
    { text: "Quantity", value: nameof<StatementChargeModel>("quantity") },
    {
      text: "Cost Per Item",
      value: nameof<StatementChargeModel>("unitAmount")
    },
    {
      text: "Recurrence",
      value: nameof<StatementChargeModel>("recurrenceType")
    }
  ];

  private get charges() {
    return this.statement.charges;
  }

  @Watch("vesselEnquiryId", { immediate: true })
  private async onVesselEnquiryIdChanged() {
    try {
      this.loading = true;
      const response = await Api.StatementService.apiStatementVesselEnquiryVesselEnquiryIdGet(
        this.vesselEnquiryId
      );

      this.statement = response.data;
    } finally {
      this.loading = false;
    }
  }

  private async onDeleteCharge(charge: StatementChargeModel) {
    try {
      await Api.StatementService.apiStatementChargeDelete(
        charge.statementChargeId
      );
      await this.onVesselEnquiryIdChanged();
      snackbarModule.setSnackbarMessage("Invoice charge deleted");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to delete invoice charge");
    }
  }
}





















































import { VForm } from "@/types/vForm";
import {
  StatementChargeModel,
  StatementChargeRecurrenceType,
  StatementChargeUpsertModel
} from "@/api/generated";
import {
  Vue,
  Component,
  PropSync,
  Prop,
  Ref,
  Watch
} from "vue-property-decorator";
import api from "@/api";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import StatementChargeRecurrenceSelect from "@/components/common/statement/StatementChargeRecurrenceSelect.vue";

const snackbarModule = getModule(SnackbarModule);

@Component({ components: { StatementChargeRecurrenceSelect } })
export default class StatementChargeUpdateDialog extends Vue {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop(Object) private statementCharge!: StatementChargeModel;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private model: StatementChargeUpsertModel = {
    description: "",
    quantity: 0,
    unitAmount: 0,
    recurrenceType: StatementChargeRecurrenceType.NUMBER_0
  };
  private items: string[] = [
    "Accomodation",
    "Covid Test",
    "Flight",
    "Flight ABZ-LHR-ACC",
    "Flight ABZ-LHR-BAH-CMB",
    "Flight ABZ-LHR-CMB",
    "Flight ACC-LHR-ABZ",
    "Flight CMB-BAH-LHR-ABZ",
    "Flight CMB-LHR-ABZ",
    "Initial Contract Set Up Fee",
    "Mobilisation Fee & POE Registration India",
    "Mobilisation Fee & POE Registration Sri Lanka",
    "Monthly Agents Fee",
    "Quarantine Costs",
    "Transport"
  ];

  @Watch("syncedDialog")
  private onDialogChange() {
    if (this.syncedDialog) {
      return;
    }

    this.form.reset();
  }

  @Watch("statementCharge")
  private onChargeChange() {
    this.model = { ...this.statementCharge };
  }

  private async onEditLineItem() {
    if (!this.statementCharge.statementChargeId) {
      return;
    }

    try {
      this.loading = true;
      await api.StatementService.apiStatementChargeStatementChargeIdPut(
        this.statementCharge.statementChargeId,
        this.model
      );
      snackbarModule.setSnackbarMessage("Invoice line item updated");
      this.$emit("charge-updated");
    } catch {
      snackbarModule.setSnackbarMessage(
        "Failed to update line item for invoice"
      );
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vessel-enquiry-invoice-table"},[_c('base-data-table',{attrs:{"title":"Invoice Line Items","headers":_vm.headers,"items":_vm.charges,"loading":_vm.loading,"no-data-text":"No invoice line items"},on:{"create-item":function($event){return _vm.$emit('add-statement-charge')}},scopedSlots:_vm._u([{key:"item.recurrenceType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("recurrenceTypeFilter")(item.recurrenceType))+" ")]}},{key:"item.unitAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyGbp")(item.unitAmount))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","min-width":"120"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('update-charge', item)}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.onDeleteCharge(item)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }























import { StatementChargeRecurrenceType } from "@/api/generated";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class StatementChargeRecurrenceSelect extends Vue {
  private items: Array<any> = [
    {
      key: StatementChargeRecurrenceType.NUMBER_0,
      value: "First Invoice Only"
    },
    { key: StatementChargeRecurrenceType.NUMBER_1, value: "Last Invoice Only" },
    { key: StatementChargeRecurrenceType.NUMBER_2, value: "Every Invoice" }
  ];
}
